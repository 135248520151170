import * as React from "react";
import { useState } from "react";
import LayoutService from "../../templates/LayoutService";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import BuySixHourBtn from "../../components/buttons/BuySixHourBtn";
import { Link } from "gatsby";
import "../../styles/components/titles.module.scss";
import "../../styles/components/header.module.scss";
import "../../styles/components/content.module.scss";
import "../../styles/components/contenttoggles.scss";
import { imgBox } from "../../styles/components/boxes.module.scss";
import {
  bannerBlock,
  bannerBlockHeadline,
  bannerBlockTagline,
  bannerBlockTitleSm,
  pageContentBlock,
  pageServiceDivider,
  serviceInfoBlock,
  serviceInfoBtns,
  pageCalloutBlock,
  pageContentMedia,
} from "../../styles/components/blocks.module.scss";
import { btn } from "../../styles/components/buttons.module.scss";
import { hideBlockSM } from "../../styles/components/helpers.module.scss";
import SixHourInfo from "../../components/blocks/SixHourInfo";
import { listStrong } from "../../styles/components/lists.module.scss";
import GallerySixHour from "../../components/galleries/GallerySixHour";

const SixHourPage = () => {
  const [isActive, setActive] = useState(true);

  const toggleClass = () => {
    setActive(!isActive);
  };

  return (
    <LayoutService>
      <Helmet>
        <title>
          6-Hour Private Party Boat Charter | Tampa Bay Boating Adventures
        </title>
        <meta
          name="description"
          content="“It doesn’t get any better than this!” Book a six-hour party boat charter and see how Tampa REALLY parties. Up to six guests. Book today."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <header className="container">
        <div className="row">
          <div className="col-xxs-12">
            <div className={bannerBlock}>
              <div className={imgBox}>
                <StaticImage
                  alt="Friends raising drinks with TBBA logos on cups near Downtown Tampa, FL."
                  src="../../images/banners/friends-raising-drinks-with-tbba-logos.png"
                />
              </div>
              <div className={bannerBlockHeadline}>
                <h1 className={bannerBlockTagline}>
                  6-Hour Private Party Boat in Tampa
                </h1>
                <span className={bannerBlockTitleSm}>
                  Experience <br className={hideBlockSM} />
                  Tampa Bay
                </span>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main className="container">
        <div className="row">
          <div className="col-xxs-12">
            <GallerySixHour />
            <div className={serviceInfoBlock}>
              <h2>6-Hour Party Boat Package Summary</h2>
              <div
                className={serviceInfoBtns}
                style={{
                  display: "flex",
                  textAlign: "center",
                
                  justifyContent: "flex-start",
                }}
              >
                <BuySixHourBtn />

                <Link
                  to="tel:8137428178"
                  className={`${btn}`}
                  style={{ marginRight: "10px", background: "#0DB0E7" }}
                >
                  Call (813) 742-8178
                </Link>

                {/* <a href="#description" className={btn} onClick={toggleClass}>{isActive ? "Hide Description" : "View Description"}</a> */}
              </div>

              <SixHourInfo />
            </div>
            <div
              id="pageContentContainer"
              className={isActive ? "pageContentOpen" : "noClass"}
            >
              <div className="anchor" id="description"></div>
              <div className={pageContentBlock}>
                <hr className={pageServiceDivider} />
                <h2>Party With Your Friends on Our 6-Hour Party Boat</h2>
                <h3>
                  Explore sandbars, islands, and more on our 6-hour private
                  party boat!
                </h3>
                <div className={pageContentMedia}>
                  <div className={imgBox}>
                    <StaticImage
                      alt="Hanging out with friends in Tampa Bay, FL."
                      src="../../images/banners/hanging-out-with-friends-in-tampa-bay.jpg"
                    />
                  </div>
                </div>
                <p>
                  Going to the bars on South Howard Avenue can be fun, but if
                  you really want to get the party going, how about chartering a
                  private party boat and hitting the water for a day of nautical
                  fun? When you sign up for our 6-hour private party boat
                  charter, you and your friends will have a blast exploring
                  local islands and partying at sandbars, all while soaking up
                  the sun and posting pics that will make you the envy of
                  Instagram. You can bring any alcohol you want on our party
                  boats, so you can turn this voyage into your own personal
                  booze cruise. After all, you’re only young once, so why not
                  live it to the fullest?
                </p>
                <div
                  className={serviceInfoBtns}
                  style={{
                    display: "flex",
                    textAlign: "center",
                   
                    justifyContent: "flex-start",
                  }}
                >
                  <BuySixHourBtn />
                  <Link
                    to="tel:8137428178"
                    className={`${btn}`}
                    style={{ marginRight: "10px", background: "#0DB0E7" }}
                  >
                    Call (813) 742-8178
                  </Link>
                </div>
                <hr />
                <h2>
                  Explore Beer Can Island, local sandbars, private beaches, and
                  more on our private party boat charter!
                </h2>
                <p>
                  When you sign up for our 6-hour private party boat charter,
                  you’ll be able to take the party almost anywhere you want! If
                  you and your friends want to party on a private island, hit
                  the sandbar with other college-aged kids, or head over to
                  Armature Works to hit the bars for a bit, we can make it
                  happen. Some of our most popular destinations include:
                </p>
                <ul className={listStrong}>
                  <li>
                    <strong>Beer Can Island</strong> - This island is a hidden
                    gem of the Tampa Bay area and is known as a big “party”
                    island. We can take you and your friends here so you can be
                    part of the wild and fun atmosphere.
                  </li>
                  <li>
                    <strong>Tampa Riverwalk</strong> - Located in the heart of
                    downtown Tampa, this 2.5 mile stretch is filled with popular
                    bars and great restaurants. We can dock the boat here so you
                    and your friends can explore this area for a little and then
                    hop back on the boat when you’re ready to head somewhere
                    else.
                  </li>
                  <li>
                    <strong>Sandbars</strong> - Regulars out on the water know
                    that if you want the ideal spot to party, you need to find a
                    sandbar! Our local captains know all the best sandbars that
                    are always packed with college-aged kids ready to have a
                    good time!
                  </li>
                </ul>
                <p>
                  Other great places to explore include Sparkman Wharf, Fort De
                  Soto, and the many private islands tucked away in the tropical
                  shade of Hillsborough and Old Tampa Bays. If you want to
                  anchor down in the middle of the water for an impromptu swim
                  session or want some privacy away from other boats, just say
                  the word! We want you and your friends to have a truly
                  unforgettable experience on your private party voyage, which
                  is why we give you control of your destinations!
                </p>
                <hr />
                <h2>Bring Whatever Alcohol You Want on Our Party Boat!</h2>
                <p>
                  There’s nothing better than catching a buzz with your friends
                  while out on the water! While we don’t provide any alcohol,
                  you can bring aboard any kind of booze you want! Fill your
                  cooler with beer, liquor, or hard seltzers to make this your
                  own personal booze cruise and get your drink on all day long!
                  All we ask is that you are at least 21 years of age and that
                  you drink responsibly.
                </p>
                <div
                  className={serviceInfoBtns}
                  style={{
                    display: "flex",
                    textAlign: "center",
                   
                    justifyContent: "flex-start",
                  }}
                >
                  <BuySixHourBtn />
                  <Link
                    to="tel:8137428178"
                    className={`${btn}`}
                    style={{ marginRight: "10px", background: "#0DB0E7" }}
                  >
                    Call (813) 742-8178
                  </Link>
                </div>
                <hr />
                <h2>
                  Our Party Boat Includes the Essentials &amp; You Can Bring
                  Anything Else!
                </h2>
                <p>
                  At Tampa Bay Boating Adventures, our party boats include the
                  essentials: a licensed captain, fresh water, and a premium
                  sound system. We’ll make sure you stay hydrated and don’t have
                  to worry about driving the boat as you relax in style. But you
                  can also bring along your own food, floats, or anything else
                  you might want to ensure you have the best day possible!
                </p>
                <div className={pageCalloutBlock}>
                  <p>
                    Our 6-hour party boat charter is perfect for
                    bachelor/bachelorette parties, birthdays, wedding showers,
                    and more!
                  </p>
                </div>
                <hr />
                <h2>
                  Get the party started by scheduling your 6-hour private party
                  boat charter with Tampa Bay Boating Adventures today!
                </h2>
                <p>
                  Ready to make memories that will last a lifetime out on the
                  water with your friends? Then it’s time to book with Tampa Bay
                  Boating Adventures. All you need to do is fill out{" "}
                  <Link to="/contact">our submission form</Link> or{" "}
                  <a href="mailto:ahoy@tampabayboatingadventures.com">
                    send us an email
                  </a>{" "}
                  and we’ll get right back to you! But don’t wait! With all the
                  local college-age kids looking to party, our schedule fills up
                  quickly, so sign up ASAP to ensure you can reserve our 6-hour
                  private party boat charter on the day you want!
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </LayoutService>
  );
};

export default SixHourPage;
