import * as React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'
import { galleryContainer } from '../../styles/components/galleries.module.scss'
import '../../styles/components/swiper.css'
import { imgBox } from '../../styles/components/boxes.module.scss'
import { StaticImage } from 'gatsby-plugin-image'

const GallerySixHour = () => {
  return (
    <div className={galleryContainer}>
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className={imgBox}>
            <StaticImage
              alt="Beer Can Island and party boats near Tampa Bay, FL."
              src="../../images/galleries/six-hour-charter/beer-can-island-and-party-boats-near-tampa-bay.jpg"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={imgBox}>
            <StaticImage
              alt="Friends with drinks on a party boat in Tampa Bay, Florida."
              src="../../images/galleries/six-hour-charter/friends-on-party-boat-with-drinks.jpg"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={imgBox}>
            <StaticImage
              alt="Friends raising glasses on a Tampa Bay party boat trip."
              src="../../images/galleries/six-hour-charter/friends-raising-glasses-on-party-boat.jpg"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={imgBox}>
            <StaticImage
              alt="Friends with drinks and a girl in a guy's lap on a party boat near Tampa, FL."
              src="../../images/galleries/six-hour-charter/friends-with-drinks-and-girl-in-guys-lap-on-party-boat.jpg"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={imgBox}>
            <StaticImage
              alt="Hot girls and a guy on a party boat in Tampa Bay."
              src="../../images/galleries/six-hour-charter/hot-girls-and-guy-on-party-boat.jpg"
            />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default GallerySixHour